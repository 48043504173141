<template>
    <v-container fluid>
        <v-card flat :loading="loading">
            <Page-Header
                :title="$t('alerts.alerts')"
                icon="mdi-account-alert-outline"
            />
            <v-card-text>
                <v-text-field
                    dense
                    outlined
                    :loading="loading"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                >
                </v-text-field>
                <v-data-table
                    :headers="headers"
                    :items="alerts"
                    :search="search"
                    :loading="loading"
                    style="overflow: hidden;"
                    :loading-text="$t('loading')"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row>
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="
                                                isInRole(roles.alert.update) &&
                                                    (getUserInfo().id ==
                                                        item.createdUser.id ||
                                                        getUserInfo()
                                                            .isSuperAdmin)
                                            "
                                            class="mr-2"
                                            @click="editAlert(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("edit") }} </span>
                                </v-tooltip></v-col
                            >
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="
                                                isInRole(roles.alert.delete) &&
                                                    (getUserInfo().id ==
                                                        item.createdUser.id ||
                                                        getUserInfo()
                                                            .isSuperAdmin)
                                            "
                                            @click="openDeleteAlertDialog(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("delete") }}</span>
                                </v-tooltip></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`item.createdDate`]="{ item }">
                        <div
                            :title="
                                item.createdDate | moment('yyyy-MM-DD hh:mm A')
                            "
                        >
                            {{ item.createdDate | moment("from", "now") }}
                        </div>
                    </template>
                    <template v-slot:[`item.alertToDepartments`]="{ item }">
                        {{
                            item.alertToDepartments
                                ? $t("departments.department")
                                : item.alertToEmployees
                                ? $t("employees.employee2")
                                : item.alertToPositions
                                ? $t("positionTypes.position")
                                : ""
                        }}
                    </template>
                    <template v-slot:[`item.alertTo`]="{ item }">
                        <v-btn
                            @click="showAlertDestinationsDialog(item.alertGuid)"
                            text
                            color="blue"
                            :disabled="!isInRole(roles.alert.alertDestinations)"
                            v-if="item.multipleDestinations"
                            >{{ $t("alerts.ui.multipleDestinations") }}</v-btn
                        >
                        <span v-else>{{
                            item.destinationName ? item.destinationName : "yy"
                        }}</span>
                    </template>
                </v-data-table>
                <confirm-dialog
                    :openDialog="dialogDelete"
                    :onClicked="confirmDeleteAlert"
                    :onClose="closeDeleteAlertDialog"
                    toolBarColor="red darken-2"
                ></confirm-dialog>
                <Alert-Destinations
                    :dialog="alertDestinationsDialog"
                    :alertGuid="alertGuid"
                    :close="closeAlertDestinationsDialog"
                    :key="alertGuid"
                />
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import AlertDestinations from "./AlertDestinations.vue";
import AlertsApi from "../../Api/AlertsApi.js";

export default {
    components: { ConfirmDialog, PageHeader, AlertDestinations },
    data() {
        return {
            alertDestinationsDialog: false,
            alertGuid: null,
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                {
                    text: this.$t("alerts.title"),
                    value: "title"
                },
                {
                    text: this.$t("alerts.details"),
                    value: "strippedDetails"
                },
                {
                    text: this.$t("alerts.date"),
                    value: "createdDate"
                },
                {
                    text: this.$t("alerts.ui.alertAs"),
                    value: "senderEmployeeName"
                },
                {
                    text: this.$t("alerts.ui.alertType"),
                    value: "alertToDepartments"
                },
                {
                    text: this.$t("alerts.alertTo"),
                    value: "alertTo"
                },
                {
                    text: this.$t("users.userName"),
                    value: "createdUser.userName"
                },
                { text: "", value: "actions" }
            ],
            alerts: [],
            editedItem: {},
            defaultItem: {
                alertGuid: "",
                title: "",
                details: ""
            }
        };
    },
    created() {
        if (!this.isInRole(this.roles.alert.window))
            this.redirectUnauthorizedUsers();
        this.getAlerts();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDeleteAlertDialog();
        }
    },
    methods: {
        editAlert(alert) {
            if (alert.alertToDepartments)
                this.$router.push({
                    name: "alerts.alertToDepartments",
                    params: {
                        id: alert.alertGuid
                    }
                });
            else if (alert.alertToEmployees)
                this.$router.push({
                    name: "alerts.alertToEmployees",
                    params: {
                        id: alert.alertGuid
                    }
                });
            else if (alert.alertToPositions)
                this.$router.push({
                    name: "alerts.alertToPositions",
                    params: {
                        id: alert.alertGuid
                    }
                });
        },
        openDeleteAlertDialog(item) {
            this.editedIndex = this.alerts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        confirmDeleteAlert() {
            var deleteItem = this.alerts[this.editedIndex];

            AlertsApi.Delete(deleteItem.alertGuid)
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.getAlerts();
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(error => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(error);
                })
                .finally(() => {
                    this.closeDeleteAlertDialog();
                });
        },
        closeDeleteAlertDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getAlerts() {
            this.loading = true;

            AlertsApi.Get()
                .then(response => {
                    this.alerts = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        showAlertDestinationsDialog(guid) {
            this.alertGuid = guid;
            this.alertDestinationsDialog = true;
        },
        closeAlertDestinationsDialog() {
            this.alertDestinationsDialog = false;
        }
    }
};
</script>
