var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('alerts.alerts'),"icon":"mdi-account-alert-outline"}}),_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","outlined":"","loading":_vm.loading,"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.alerts,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                                            _vm.isInRole(_vm.roles.alert.update) &&
                                                (_vm.getUserInfo().id ==
                                                    item.createdUser.id ||
                                                    _vm.getUserInfo()
                                                        .isSuperAdmin)
                                        )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editAlert(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(
                                            _vm.isInRole(_vm.roles.alert.delete) &&
                                                (_vm.getUserInfo().id ==
                                                    item.createdUser.id ||
                                                    _vm.getUserInfo()
                                                        .isSuperAdmin)
                                        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.openDeleteAlertDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)],1)]}},{key:"item.createdDate",fn:function(ref){
                                        var item = ref.item;
return [_c('div',{attrs:{"title":_vm._f("moment")(item.createdDate,'yyyy-MM-DD hh:mm A')}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdDate,"from", "now"))+" ")])]}},{key:"item.alertToDepartments",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.alertToDepartments ? _vm.$t("departments.department") : item.alertToEmployees ? _vm.$t("employees.employee2") : item.alertToPositions ? _vm.$t("positionTypes.position") : "")+" ")]}},{key:"item.alertTo",fn:function(ref){
                                        var item = ref.item;
return [(item.multipleDestinations)?_c('v-btn',{attrs:{"text":"","color":"blue","disabled":!_vm.isInRole(_vm.roles.alert.alertDestinations)},on:{"click":function($event){return _vm.showAlertDestinationsDialog(item.alertGuid)}}},[_vm._v(_vm._s(_vm.$t("alerts.ui.multipleDestinations")))]):_c('span',[_vm._v(_vm._s(item.destinationName ? item.destinationName : "yy"))])]}}],null,true)}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.confirmDeleteAlert,"onClose":_vm.closeDeleteAlertDialog,"toolBarColor":"red darken-2"}}),_c('Alert-Destinations',{key:_vm.alertGuid,attrs:{"dialog":_vm.alertDestinationsDialog,"alertGuid":_vm.alertGuid,"close":_vm.closeAlertDestinationsDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }